import useDialog from '@bfly/ui2/useDialog';
import useRefWithInitialValueFactory from '@restart/hooks/useRefWithInitialValueFactory';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import FarceActions from 'farce/Actions';
import React, { useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchQuery, graphql } from 'react-relay';
import { type Store as ReduxStore } from 'redux';
import {
  SocketIoSubscriptionClient,
  SubscriptionClientOptions,
  createFetch,
  createSubscribe,
  useAuthToken,
} from 'relay-network-layer';
import { BatchConfig } from 'relay-network-layer/createFetch';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import io from 'socket.io-client';
import localStore from 'store/dist/store.modern';

import type { AuthContextValue } from 'components/AuthContext';
import AuthContext, { SetAccessOptions } from 'components/AuthContext';
import { routes } from 'routes/config';
import Analytics from 'utils/Analytics';
import ExternalRedirect from 'utils/ExternalRedirect';
import podTargetingHeaders from 'utils/podTargetingHeaders';
import sessionStore from 'utils/sessionStore';

import getDomainSubdomainLabel from '../shared/utils/getDomainSubdomainLabel';
import getGraphqlUpstreamKey from '../shared/utils/regionKeys';
import ViewerContextManager from '../utils/ViewerContextManager';
import handlerProvider from '../utils/handlerProvider';
import missingFieldHandlers from '../utils/missingFieldHandlers';
import tokenStorage, { SessionInfo, TokenState } from '../utils/tokenStorage';
import useInactivityTimeout from '../utils/useInactivityTimeout';
import { AuthProvider_SessionInfoQuery as SessionInfoQuery } from './__generated__/AuthProvider_SessionInfoQuery.graphql';

export const SHOW_SIGN_IN_CONFIRMATION_STATE_KEY =
  'bfly:show-sign-in-confirmation';

const batchConfig: BatchConfig = {
  enabled: true,
  shouldRequestBeBatched: (_o, _v, cacheConfig) => {
    return !cacheConfig?.metadata?.defer;
  },
};

interface Props {
  store: ReduxStore;
  children: (childArgs: {
    environment: Environment;
    viewerLocalId: string | null;
    auth: AuthContextValue;
  }) => React.ReactNode;
}

class MultiRegionSocketIoSubscriptionClient extends SocketIoSubscriptionClient {
  private organizationDescriptor: string | null = null;

  constructor(options: Omit<SubscriptionClientOptions, 'io'>) {
    super({ ...options, io });
  }

  authenticate() {
    if (!this.token) {
      return;
    }

    this.emitTransient('authenticate', {
      token: this.token,
      organizationDescriptor: this.organizationDescriptor,
    });
  }

  setOrganizationSlug(organizationSlug: string | null) {
    this.organizationDescriptor =
      organizationSlug && `slug ${organizationSlug}`;

    this.authenticate();
  }
}

function createEnvironmentInfo(
  token?: string,
  apiOrigin?: string,
  relayStore = new Store(new RecordSource()),
) {
  let organizationSlug: string | null = null;

  if (!apiOrigin) {
    const graphqlUpstreamKey = getGraphqlUpstreamKey();
    const apiUrl =
      sessionStorage.getItem(graphqlUpstreamKey) ||
      globalThis.bflyConfig.GRAPHQL_UPSTREAM;
    apiOrigin = apiUrl;
  }

  const subscribeFn = createSubscribe({
    subscriptionClientClass: MultiRegionSocketIoSubscriptionClient,
    url: `${apiOrigin}/socket.io/graphql`,
    token,
  });

  const network = Network.create(
    createFetch({
      url: `${apiOrigin}/graphql`,
      batch: batchConfig,
      init: () => {
        const headers = new Headers();

        if (organizationSlug) {
          headers.set('Olympus-Organization', `slug ${organizationSlug}`);
        }

        if (!bflyConfig.IS_PROD) {
          podTargetingHeaders
            .entries()
            .forEach(([podTargetHeader, headerValue]) => {
              headers.set(podTargetHeader, headerValue);
            });
        }

        return { headers };
      },
      authorization: token && { token, scheme: 'JWT' },
      throwErrors: false,
    }),
    subscribeFn,
  );

  // const relayStore = new Store(new RecordSource());
  relayStore.holdGC(); // Disable GC on the relayStore.

  const environment = new Environment({
    handlerProvider,
    network,
    store: relayStore,
    missingFieldHandlers,
  });

  // FIXME: Relay wraps the network so we can't add this before
  (environment.getNetwork() as any).setOrganizationSlug = (
    nextOrganizationSlug: string | null,
  ) => {
    organizationSlug = nextOrganizationSlug;
    subscribeFn.client.setOrganizationSlug(nextOrganizationSlug);
  };

  return { environment, subscribeFn };
}

async function fetchSessionInfo(
  environment: Environment,
): Promise<SessionInfo> {
  const result = await fetchQuery<SessionInfoQuery>(
    environment,
    graphql`
      query AuthProvider_SessionInfoQuery {
        viewer {
          localId
          email
          domain {
            id
          }
          profile {
            id
          }
          domain {
            federatedIdentityProvider {
              clientId
              logoutEndpointBaseUrl
            }
          }
          authorizationExpiration
        }
      }
    `,
    {},
  ).toPromise();
  const { viewer } = result!;
  const federatedIdentityProvider = viewer!.domain?.federatedIdentityProvider;

  return {
    localId: viewer!.localId!,
    email: viewer!.email,
    expiresAt: new Date(viewer!.authorizationExpiration!).getTime(),
    federatedIdentityProvider:
      federatedIdentityProvider as SessionInfo['federatedIdentityProvider'],
    domainId: viewer!.domain != null ? viewer!.domain.id : null,
    userId: viewer!.profile!.id,
  };
}

function useAuthState({
  onTokenExpired,
  reduxStore,
}: {
  reduxStore: ReduxStore;
  onTokenExpired: () => any | Promise<any>;
}) {
  const [tokenState, setTokenState] = useAuthToken<TokenState>({
    onTokenExpired: async () => {
      await onTokenExpired();

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearAccessToken(false);
    },
    tokenStorage,
  });

  const environmentInfoRef = useRefWithInitialValueFactory(() => {
    if (tokenState) {
      Analytics.identify(tokenState.localId, tokenState.email);
    }

    // TODO: It would be best to reset analytics identity here if there is no
    //  tokenState, but this will generate a new anonymous ID on Segment, which
    //  is not what we want.

    return createEnvironmentInfo(tokenState?.accessToken);
  });

  // These don't use useCallback because they're wrapped with a useMemo that
  //  accesses a ref's current value in <AuthProvider> below.
  const getNextEnvironmentInfo = (accessToken?: string) => {
    environmentInfoRef.current.subscribeFn.close();
    return createEnvironmentInfo(accessToken);
  };

  const setAccessToken = async (
    accessToken: string,
    options?: SetAccessOptions,
  ) => {
    if (accessToken === tokenState?.accessToken) {
      // No need to do anything here.
      return;
    }

    const nextEnvironmentInfo = getNextEnvironmentInfo(accessToken);

    // Before setting the tokenState, lookup the localId from the server
    // and add it to the state that'll be kept in local stroage.
    const sessionInfo = await fetchSessionInfo(
      nextEnvironmentInfo.environment,
    );

    Analytics.identify(sessionInfo.localId, sessionInfo.email);

    environmentInfoRef.current = nextEnvironmentInfo;
    await setTokenState({
      accessToken,
      ...sessionInfo,
    });

    localStore.remove('bfly:hasSeenDataCollection');
    sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false); // prevent sign in confirmation

    // Audit logs have been suspended
    if (options?.trackLogin && sessionInfo.domainId) {
      // registerLoginEvent(nextEnvironmentInfo.environment, {
      //   userId: sessionInfo.userId,
      //   domainId: sessionInfo.domainId,
      //   authType: options.authType,
      // });
    }
  };

  const clearAccessToken = async (redirectToRoot: boolean) => {
    ViewerContextManager.clear();

    // TODO: It would be ideal to reset analytics identity here, but we don't
    //  want to generate a new anonymous ID.

    // Audit logs have been suspended
    // if (tokenState && tokenState.domainId) {
    //   registerLogoutEvent(environmentInfoRef.current.environment, {
    //     userId: tokenState.userId,
    //     domainId: tokenState.domainId,
    //   });
    // }

    const subdomainLabel = getDomainSubdomainLabel();
    const graphqlUpstreamKey = getGraphqlUpstreamKey(subdomainLabel);
    sessionStore.remove(graphqlUpstreamKey);

    if (tokenState?.federatedIdentityProvider) {
      const { federatedIdentityProvider } = tokenState;

      // We don't need to update token state here, as we'll be doing a hard
      // redirect off the page.
      tokenStorage.clear();

      if (!redirectToRoot) {
        // This is a tiny optimization; we don't need to re-render whenever
        //  the resolved match changes, as we only need this value here.
        const { pathname, search } = (reduxStore.getState() as any).found
          .resolvedMatch.location;
        Sentry.addBreadcrumb({
          level: Severity.Info,
          message: 'sso logout sessionStore',
          data: {
            storeUsed: (sessionStore as any).storage.name || 'unknown',
            version: sessionStore.version,
          },
        });
        sessionStore.set('ssoLogoutRedirectPath', `${pathname}${search}`);
      }

      const params = new URLSearchParams({
        /* eslint-disable @typescript-eslint/naming-convention */
        client_id: federatedIdentityProvider.clientId,
        logout_uri: `${window.location.origin}/`,
        /* eslint-enable @typescript-eslint/naming-convention */
      });

      ExternalRedirect.redirect(
        `${federatedIdentityProvider.logoutEndpointBaseUrl}?${params}`,
      );
      return;
    }

    environmentInfoRef.current = getNextEnvironmentInfo();
    await setTokenState(null);

    if (redirectToRoot) {
      reduxStore.dispatch(FarceActions.replace(routes.rootRoute()));
    }
  };

  return {
    tokenState,
    environment: environmentInfoRef.current.environment,
    setAccessToken,
    clearAccessToken,
  };
}

function AuthProvider({ children, store: reduxStore }: Props) {
  const intl = useIntl();
  const dialog = useDialog();

  const authState = useAuthState({
    reduxStore,
    onTokenExpired: () =>
      dialog.open(
        <FormattedMessage
          id="authProvider.authExpired"
          defaultMessage="Your session is about to expire. Please login in again to continue."
        />,
        {
          hideCancel: true,
          title: intl.formatMessage({
            id: 'authProvider.expiredTitle',
            defaultMessage: 'Session Expired',
          }),
        },
      ),
  });

  useInactivityTimeout({
    enabled: !!authState.tokenState,
    environment: authState.environment,
    onTimeout() {
      authState.clearAccessToken(true);
    },
  });

  // We wrap the state in a Ref so that the context value doesn't
  // change even if auth does
  const authStateRef = useRef(authState);
  authStateRef.current = authState;

  const authContext = useMemo(
    () => ({
      setAccessToken: (accessToken: string, options: SetAccessOptions) =>
        authStateRef.current.setAccessToken(accessToken, options),
      clearAccessToken: (redirectToRoot = false) =>
        authStateRef.current.clearAccessToken(redirectToRoot),
      isAuthenticated: () => !!authStateRef.current.tokenState,
      getTokenInfo: () => {
        const { accessToken, expiresAt } = authStateRef.current.tokenState!;
        return { accessToken, expiresAt };
      },
      createEnvironment(apiOrigin?: string) {
        return createEnvironmentInfo(
          authStateRef.current.tokenState?.accessToken,
          apiOrigin,
          authStateRef.current.environment.getStore() as Store,
        ).environment;
      },
    }),
    [],
  );

  return (
    <AuthContext.Provider value={authContext}>
      {children({
        environment: authState.environment,
        viewerLocalId: authState.tokenState?.localId ?? null,
        auth: authContext,
      })}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
